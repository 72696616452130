
<!--
  ~ VNCcontact+ : A new level of contact management
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="contact-dialog">
    <div class="contact__dialog-header" *ngIf="!isSearchView">
        <div class="mobile-back-button">
            <button mat-button (click)="close()">
                <mat-icon class="mdi-24px" fontSet="mdi" fontIcon="mdi-arrow-left"></mat-icon>
            </button>
        </div>
        <div class="header_lbl disable-select">
            {{ ((!isEdit)? 'CREATE_CONTACT_GROUP' : 'EDIT_CONTACT_GROUP') | translate }}
        </div>
        <div class="desktop-close-button">
            <button mat-button (click)="close()">
                <mat-icon class="disable-select">close</mat-icon>
            </button>
        </div>
        <div class="mobile-back-button">
            <button mat-button (click)="openSearchView(true)">
                <mat-icon class="mdi-24px" fontSet="mdi" fontIcon="mdi-magnify"></mat-icon>
            </button>
        </div>
    </div>
    <div class="contact__dialog-header search-header" *ngIf="isSearchView">
        <div class="mobile-back-button">
            <button mat-button (click)="openSearchView(false)">
                <mat-icon class="mdi-20px" fontSet="mdi" fontIcon="mdi-arrow-left"></mat-icon>
            </button>
        </div>
        <div class="header_lbl disable-select">
            <mat-form-field floatLabel="never">
                <input matInput class="form-control" [(ngModel)]="searchText" name="label"
                    placeholder="{{ 'SEATCH_CONTACTS' | translate }}" autocomplete="nope" (keydown)="searchFromText($event)" />
            </mat-form-field>
        </div>
        <div class="mobile-back-button">
            <button mat-button *ngIf="searchText.length > 0" (click)="emptySearchText()">
                <mat-icon class="disable-select">close</mat-icon>
            </button>
        </div>
    </div>
    <div class="contact__dialog-body contact__dialog-body--pad" [ngClass]="{'is-contact-select-item': finalContacts.length > 0}" [class.is-search-view-open]="isSearchView">
        <div class="content">
            <div class="app-disabled" *ngIf="isLoading">
                <vp-custom-spinner></vp-custom-spinner>
            </div>
            <div class="main">
                <div class="group-name" [class.is-search-view-open]="isSearchView">
                    <div class="icon">
                        <div class="icon-div">
                            <!--
                            <div class="user-avatar" (click)="openCropperDialog()">
                            -->
                            <div class="user-avatar">
                                <vp-avatar [user]="{firstLastCharacters: '', color: '#ededed', avatarURL: avatarBase64 }" [isGroup]=true> </vp-avatar>
                            </div>
                        </div>
                    </div>
                    <div class="name">
                        <mat-form-field>
                            <input matInput placeholder="{{'CONTACT_GROUP_NAME' | translate}} *" [(ngModel)]="groupName"
                                autocomplete="nope" />
                        </mat-form-field>
                    </div>
                </div>
                <div class="search-part" [class.is-search-view-open]="isSearchView">
                    <div class="search">
                        <mat-form-field>
                            <input matInput placeholder="{{'SEARCH_DOT' | translate}}" [(ngModel)]="searchText"
                                autocomplete="nope" />
                            <mat-icon matSuffix (click)="searchTextWord()">search</mat-icon>
                        </mat-form-field>
                    </div>
                    <div class="dropdown">
                        <mat-form-field>
                            <mat-label>{{'SEARCH_IN' | translate }}:</mat-label>
                            <mat-select [(ngModel)]="searchIn" (selectionChange)="onChangeType()">
                              <mat-option *ngFor="let type of searchType" [value]="type.value">
                                {{type.viewValue}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                    </div>
                </div>
                <div *ngIf="!!finalContacts && finalContacts.length > 0" class="all-selected-item" [class.is-search-view-open]="isSearchView">
                    <div *ngFor="let contactItem of finalContacts" class="selected-contact-item">
                        <div class="close-icon" (click)="removeContact(contactItem)">
                            <mat-icon class="mdi-14px" fontSet="mdi" fontIcon="mdi-close"></mat-icon>
                        </div>
                        <div>
                            <vp-avatar [user]="{firstLastCharacters: contactItem.firstName.charAt(0), color: contactItem.bgAvatarColor, avatarURL: contactItem.avatar}"></vp-avatar>
                        </div>
                        <div class="name">
                            {{contactItem.firstName}}
                        </div>
                    </div>
                </div>
                <div class="contact-items">
                    <mat-list [ngClass]="{'is-contact-select': finalContacts.length > 0}" (scroll)="scrollMoreContacts($event)" class="desktop-list vnc-scrollbar-verical-show-on-hover">
                        <mat-list-item *ngFor="let contact of contacts; index as i;" (click)="selectContactItem(contact, i, $event)">
                                <span class="user-avatar"><vp-avatar [user]="{firstLastCharacters: contact.firstName.charAt(0), color: contact.bgAvatarColor, avatarURL: contact.avatar}" [isGroup]=true></vp-avatar></span>
                                <span class="fullname disable-select">{{contact.fullName}}</span>
                                <span class="email-address disable-select">
                                    {{ (!!contact.emails && contact.emails.length > 0) ? "<" + contact.emails[0].email + ">" : ""}}
                                </span>
                            </mat-list-item>
                    </mat-list>
                    <mat-list [ngClass]="{'is-contact-select': finalContacts.length > 0}" (scroll)="scrollMoreContacts($event)" class="vnc-scrollbar-verical-show-on-hover mobile-list" [class.is-search-view-open]="isSearchView">
                        <mat-list-item *ngFor="let contact of contacts; index as i;" (click)="selectContactItem(contact, i, $event)">
                            <div class="all-item">
                                <div class="name-avatar">
                                    <span class="user-avatar"><vp-avatar [user]="{firstLastCharacters: contact.firstName.charAt(0), color: contact.bgAvatarColor, avatarURL: contact.avatar}" [isGroup]=true></vp-avatar></span>
                                    <span class="fullname disable-select">{{contact.fullName}}</span>
                                </div>
                                <div class="info-item-icon">
                                    <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-information-outline"></mat-icon>
                                </div>
                            </div>
                        </mat-list-item>
                    </mat-list>
                </div>
            </div>
        </div>
    </div>
    <div class="contact__dialog-footer" [class.is-search-view-open]="isSearchView">
        <div class="desktop-footer">
            <button mat-button (click)="close()">
                {{ 'CANCEL' | translate }}
            </button>
            <button mat-button class="primary-button" (click)="saveContactGroup()">
                {{ 'CREATE' | translate }}
            </button>
        </div>
        <div class="mobile-footer" *ngIf="finalContacts.length > 0">
            <div class="title">
                {{ 'SELECT_MORE_OR_ADD_TO_CONTACT_GROUP' | translate }}
            </div>
            <div class="save_footer_btn">
                <button mat-button (click)="saveContactGroup()">
                    <mat-icon>check</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>