
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Broadcaster } from "./broadcaster.service";
import { Observable, BehaviorSubject, throwError } from "rxjs";
import { Injectable } from "@angular/core";
import { Http, Response, Headers } from "@angular/http";
import { environment } from "../../../environments/environment";
import { Subject } from "rxjs";
import { AppConstants } from "../utils/app-constants";
import { LocaleService } from "./locale.service";
import { map, take } from "rxjs/operators";
import { CommonUtil } from "src/app/common/utils/common.utils";

@Injectable()
export class ConfigService {

  private lang = ["en", "de"];

  private config = {

    // Key used in local storage to store authentication token
    AUTH_TOKEN_KEY: "auth.jwt",
    "appsEnabled": [{
      "title": "VNCcontactsplus",
      "route": "/contactsplus",
      "enabled": true
    }],
    "lang": "en"
  };

  API_URL = "";
  selectedServer = false;
  currentLanguage = new Subject();
  serverURL = "https://task.vnc.biz";
  URLS: any = {};
  domain: string;
  private config$ = new BehaviorSubject<any>(this.config);
  two_factor_authentication: boolean = false;
  // serverURL = "https://dev.vnctask.vnc.biz";
  private showLoginIframeKeyboardTimer;
  worker: Worker;
  constructor(private http: Http,
    private localeService: LocaleService,
    private broadcaster: Broadcaster ) {
      if (localStorage.getItem("publicVncDirectoryUrl") !== null) {
        this.set("publicVncDirectoryUrl", localStorage.getItem("publicVncDirectoryUrl"));
      }
  }

  loadConfig() {
    const isCordovaOrElectron = environment.isCordova || environment.isElectron;
    if (!isCordovaOrElectron) {
      console.log("[loadConfig] !environment.isCordova");
      this.setAPIURL("");
      this.selectedServer = true;
    } else {
      if (localStorage.getItem("serverURL")) {
        this.selectedServer = true;
        this.setAPIURL(localStorage.getItem("serverURL").trim());
      }
    }
  }

  set(key: string, value: any): any {

    let oldValue = this.config[key];

    if (oldValue !== value) {
      // console.debug("[config.set(" + key + ")]", oldValue, " => ", value);
    }
    this.config[key] = value;
    this.config$.next(this.config);
    return this.config;
  }

  get(key): any {
    let result = this.config[key];
    // console.debug("[config.get(" + key + ")] => ", result);
    return result;
  }

  getOrThrow(key): any {

    if (!this.config.hasOwnProperty(key))
      {throw "Missing '" + key + "' in config";}

    return this.config[key];
  }

  setAPIURL(url: string): void {
    this.API_URL = url;
    console.log("[Set API URL]", url);
  }

  showError(msg) {
    console.log(`Invalid configuration: ${msg}`);
  }

  getSupportedLangs() {
    return this.lang.slice();
  }

  getDefaultLang() {
    return this.lang[0];
  }

  getConfiguredLang() {
    let lang = this.get("lang");
    if (this.lang.indexOf(lang) >= 0)
      {return lang;}
    return this.getDefaultLang();
  }

  isAppEnabled(appTitle: string) {
    let apps = this.config["appsEnabled"] || [];
    let app = apps.filter(app => app.title === appTitle)[0];
    return app && app.enabled;
  }

  clearCache() {
    localStorage.removeItem("firebaseToken");
    localStorage.removeItem("REDMINE_URL");
    localStorage.removeItem("redmineDefaultProject");
    localStorage.removeItem("tracker_id");
    localStorage.removeItem("status_new");
    localStorage.removeItem("status_inProgress");
    localStorage.removeItem("status_completed");
  }

  loginIframe() {
    if (!this.selectedServer) {
      console.log("[config.ts] return");
      return;
  }
  let iframe = document.createElement("iframe");
    if (iframe) {
      console.log("[config.service] launching login iframe window.location", window.location);
      const initialHref = CommonUtil.isOnAndroid() ? "" : window.location.href.split("/contactplus")[0].replace(/index.html/gi, "");
      const loginUrl = `${initialHref}/assets/login.html`;
      console.log("[config.service] launching login initialHref", initialHref);
      console.log("[config.service] launching login iframe with url", loginUrl);
      iframe.id = "loginIframe";
      iframe.setAttribute("src", loginUrl);
      iframe.setAttribute("width", "100%");
      iframe.setAttribute("height", "100%");
      iframe.style.height = "100%";
      iframe.style.width = "100%";
      iframe.style.position = "fixed";
      iframe.style.zIndex = "999";
      iframe.style.border = "none";

      if (document.querySelector("body") !== null && document.querySelector("#loginIframe") === null) {
        document.querySelector("body").appendChild(iframe);
      }

      if (document.querySelector("vnc-commander") !== null) {
        let commander = <HTMLElement>document.querySelector("vnc-commander");
        commander.style.display = "none";
      }
    } else {
      console.log("[config.ts] no iframe");
    }
  }


  logoutIframe() {
    let iframe = document.createElement("iframe");
    if (iframe) {
      if (localStorage.getItem("serverURL") !== null) {
        this.API_URL = localStorage.getItem("serverURL").trim();
      }
      iframe.id = "logoutIframe";
      iframe.setAttribute("src", this.API_URL + "/api/call-logout");
      iframe.setAttribute("width", "100%");
      iframe.setAttribute("height", "100%");
      iframe.style.height = "100%";
      iframe.style.width = "100%";
      iframe.style.position = "fixed";
      iframe.style.zIndex = "999";
      iframe.style.border = "none";
      if (document.querySelector("body") !== null && document.querySelector("#logoutIframe") === null) {
        if (document.querySelector("#loginIframe") !== null) {
          document.querySelector("#loginIframe").remove();
        }
        document.querySelector("body").appendChild(iframe);
      }
    }
  }

  hideLoginIframe() {
    if (document.querySelector("#loginIframe") !== null) {
      document.querySelector("#loginIframe").remove();

      this.hideKeyboard();
    }
  }

  tfaOtpIframe() {
    console.log("[ConfigService][tfaOtpIframe] selectedServer: ", this.selectedServer);

    if (!this.selectedServer) {
      console.log("[ConfigService][tfaOtpIframe] return");
      return;
    }

    let iframe = document.createElement("iframe");
    if (iframe) {
      let initialHref = (CommonUtil.isOnAndroid()) ? "" : window.location.href.split("/contactplus/all")[0];
      const loginUrl = `${initialHref}/assets/tfa-otp.html`;
      console.log("[config.service] launching tfa otp iframe with url", loginUrl);

      iframe.id = "tfaOtpIframe";
      iframe.setAttribute("src", loginUrl);
      iframe.style.height = "100%";
      iframe.style.width = "100%";
      iframe.style.top = "0";
      iframe.style.left = "0";
      iframe.style.position = "fixed";
      iframe.style.zIndex = "999";
      iframe.style.border = "none";

      // iframe.onload = () => {
      //   console.log("[loginIframe] in onload");
      // };

      if (document.querySelector("body") !== null && document.querySelector("#tfaOtpIframe") === null) {
        document.querySelector("body").appendChild(iframe);
      }

      // by some reason, a standard '.focus()' does not work in login.html for Android
      this.showKeyboard();

      if (document.querySelector("vnc-commander") !== null) {
        let commander = <HTMLElement>document.querySelector("vnc-commander");
        commander.style.display = "none";
      }
    } else {
      console.log("[config.ts] no iframe");
    }
  }

  hideTfaOtpIframe() {
    if (document.querySelector("#tfaOtpIframe") !== null) {
      document.querySelector("#tfaOtpIframe").remove();

      this.hideKeyboard();
    }
  }

  showKeyboard() {
    if (environment.isCordova && typeof Keyboard !== "undefined")  {
      this.showLoginIframeKeyboardTimer = setTimeout(() => {
        Keyboard.show();
        this.showLoginIframeKeyboardTimer = null;
      }, 100);
    }
  }

  hideKeyboard(){
    // by some reason, a standard '.focus()' does not work in login.html for Android,
    // so we show a keyboard in 'config.service.ts' using a Keyboard class.
    // So then we needd to hide it here once login is successful

    if  (!this.showLoginIframeKeyboardTimer) {
      clearTimeout(this.showLoginIframeKeyboardTimer);
      this.showLoginIframeKeyboardTimer = null;
    } else {
      if (environment.isCordova && typeof Keyboard !== "undefined")  {
        setTimeout(() => {
          Keyboard.hide();
        }, 100);
      }
    }
  }

  getConfig() { // FIXME: this at least has a bad name. also we have two configs: this /api/config and config.json from loadConfig()
    console.log("config.service getConfig() - online? ", navigator.onLine, this.API_URL + "/api/config");
    return navigator.onLine ? this.http.get(this.API_URL + "/api/config") : this.getStoredConfig();
  }

  set language(value) {
    this.currentLanguage.next(value);
    this.localeService.registerLocale(value);
    this.broadcaster.broadcast("CHANGE_CALENDER_LOCALE", value);
    localStorage.setItem(AppConstants.CONTACT_LANGUAGE, value);

  }

  get language() {
    if (localStorage.getItem(AppConstants.CONTACT_LANGUAGE) !== null) {
      return localStorage.getItem(AppConstants.CONTACT_LANGUAGE);
    }
    return "en";
  }

  private extractData(res: Response) {
    let body = res.json();
    return body || {};
  }

  private handleErrorObservable(error: Response | any) {
    return throwError(() => new Error(error.message || error));
  }

  private handleErrorPromise(error: Response | any) {
    return Promise.reject(error.message || error);
  }

  changeServerUrl(serverURL: string): Observable<any> {
    let headers = new Headers({
      "Content-Type": "application/json",
      "Accept": "application/json"
    });

    if (serverURL.endsWith("/")) {
      serverURL = serverURL.substring(0, serverURL.lastIndexOf("/")).trim();
    }

    return this.http.get(serverURL + "/api/config", { headers: headers}).pipe(map(response => response.json()));
  }

  getAllConfig() {
    return this.config$.asObservable();
  }

  getStoredConfig(): Observable<any> {
    const response = new Subject<any>();
    try {
      const storedConfigRaw = localStorage.getItem("config");
      const storedConfig = JSON.parse(storedConfigRaw);
      const result = {
        _body: storedConfigRaw
      };
      setTimeout(() => {
        response.next(result);
      }, 1);
    } catch (error) {
      response.error(error);
    }
    return response.asObservable().pipe(take(1));
  }
}
