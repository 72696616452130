
<!--
  ~ VNCcontact+ : A new level of contact management
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div id="profile_dialog" class="vp-avatar-dialog contact-dialog">
    <div class="contact__dialog-header">
        <div class="mobile-back-button">
            <button mat-button (click)="close()">
                <mat-icon class="mdi-20px" fontSet="mdi" fontIcon="mdi-close"></mat-icon>
            </button>
        </div>
        <div class="header_lbl disable-select">
            {{ 'MY_PROFILE' | translate }}
        </div>
        <div class="desktop-close-button">
            <!--
                <button mat-button [matMenuTriggerFor]="contactOperationMenu">
                    <mat-icon class="disable-select">more_vert</mat-icon>
                </button>
            -->
            <button mat-button (click)="close()">
                <mat-icon class="disable-select">close</mat-icon>
            </button>
        </div>
        <div class="mobile-back-button">
            <button mat-button (click)="shareContact()">
                <mat-icon class="disable-select">share</mat-icon>
            </button>
        </div>
        <div class="mobile-back-button">
            <!--
                <button mat-button (click)="editLoggedInUserDetail()">
                    <mat-icon class="mdi-20px" fontSet="mdi" fontIcon="mdi-pencil"></mat-icon>
                </button>
            -->
        </div>
    </div>
    <div class="contact__dialog-body user-profile-dialog" (scroll)="scrollingDialog($event)">
        <div class="content">
            <div class="parent-item">
                <div [ngStyle]="backgroundImageStyle" class="background-imge-avatar"></div>
                <div class="parent-item-user-name">
                    <div class="user-avtar">
                        <vp-avatar
                            [user]="{firstLastCharacters: contact?.fullName.charAt(0), jid: contact.jid, color: contact.bgAvatarColor, avatarURL: contact.avatar}">
                        </vp-avatar>
                    </div>
                    <div id="profile-user-name" class="user-name">
                        <span>
                            {{ contact?.fullName | titlecase}}
                        </span>
                    </div>
                </div>
            </div>

            <div class="detail-contact" *ngIf="!!contact && contact !== null" (touchstart)="swipe($event, 'start')" (touchend)="swipe($event, 'end')">
                <vnc-tabs (selected)="tabSelect($event)" [activeFirstTab]="true">
                    <vnc-tab [id]="'contactInfo'" tabTitle="{{ 'CONTACT_INFO' | translate }}" >
                        <div class="detail-contact-tab">
                            <div class="detail-item">
                                <div class="icon">
                                    <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-account"></mat-icon>
                                </div>
                                <div class="detail">
                                    <div class="lable-detail">
                                        {{ 'FIRST_LAST_NAME_TITLE' | translate }}
                                    </div>
                                    <div class="detail-data">
                                        {{ (contact.fullName) ? ((contact.fullName.length > 30) ? contact.fullName.substr(0, 30) +
                                            '...': contact.fullName): null }}
                                    </div>
                                </div>
                            </div>
                            <mat-divider></mat-divider>
                            <div class="detail-item" *ngIf="contact.company || contact.jobTitle">
                                <div class="icon">
                                    <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-city"></mat-icon>
                                </div>
                                <div class="detail">
                                    <div class="lable-detail">
                                        {{ 'COMPANY_JOB_POSITION' | translate }}
                                    </div>
                                    <div>
                                        <span *ngIf="contact.company"> {{ contact.company }}</span>
                                        <span *ngIf="contact.company && contact.jobTitle">
                                            <mat-icon class="seprator">fiber_manual_record</mat-icon>
                                        </span>
                                        <span *ngIf="contact.jobTitle" class="type">{{ contact.jobTitle }}</span>
                                    </div>
                                </div>
                            </div>
                            <mat-divider *ngIf="contact.company || contact.jobTitle"></mat-divider>
                            <div *ngIf="!contact.isGroup && contact.address && contact.address.length > 0">
                                <div class="detail-item" *ngFor="let address of contact.address; let i= index;">
                                    <div class="icon">
                                        <mat-icon *ngIf="i===0" class="mdi-18px" fontSet="mdi" fontIcon="mdi-map-marker"></mat-icon>
                                    </div>
                                    <div class="detail">
                                        <div class="lable-detail" *ngIf="i===0">
                                            {{ 'ADDRESS' | translate }}
                                        </div>
                                        <div>
                                            <span>
                                                {{ address.street1 }}, {{ address.city }}, {{ address.state }}
                                             </span>
                                             <span>
                                                 <mat-icon class="seprator">fiber_manual_record</mat-icon>
                                             </span>
                                             <span class="type">
                                                 {{ address.address_type }}
                                             </span>
                                        </div>
                                    </div>
                                </div>
                                <mat-divider></mat-divider>
                            </div>
                            <div *ngIf="!contact.isGroup && contact.phones.length > 0">
                                <div class="detail-item" *ngFor="let phone of contact.phones; let i= index;">
                                    <div class="icon">
                                        <mat-icon *ngIf="i===0" class="mdi-18px" fontSet="mdi" fontIcon="mdi-phone"></mat-icon>
                                    </div>
                                    <div class="detail">
                                        <div class="lable-detail" *ngIf="i===0">
                                            {{ 'PHONE_MOBILE_FAX' | translate }}
                                        </div>
                                        <div>
                                            <span>
                                                {{ phone.phone}}
                                            </span>
                                            <span>
                                                <mat-icon class="seprator">fiber_manual_record</mat-icon>
                                            </span>
                                            <span class="type">
                                                {{ phone.phone_type }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <mat-divider></mat-divider>
                            </div>
                            <div *ngIf="!contact.isGroup && contact.emails.length > 0">
                                <div class="detail-item" *ngFor="let email of contact.emails; let i= index;">
                                    <div class="icon">
                                        <mat-icon *ngIf="i===0" class="mdi-18px" fontSet="mdi" fontIcon="mdi-email"></mat-icon>
                                    </div>
                                    <div class="detail">
                                        <div class="lable-detail" *ngIf="i===0">
                                            {{ 'EMAILS' | translate }}
                                        </div>
                                        <div>
                                            <span>
                                                {{ email.email}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <mat-divider></mat-divider>
                            </div>
                            <div *ngIf="!contact.isGroup && contact.urls.length > 0">
                                <div class="detail-item" *ngFor="let url of contact.urls; let i= index;">
                                    <div class="icon">
                                        <mat-icon *ngIf="i===0" class="mdi-18px" fontSet="mdi" fontIcon="mdi-link"></mat-icon>
                                    </div>
                                    <div class="detail">
                                        <div class="lable-detail" *ngIf="i===0">
                                            {{ 'WEBSITE' | translate }}
                                        </div>
                                        <div>
                                            <span>
                                                {{ url.url }}
                                            </span>
                                            <span>
                                                <mat-icon class="seprator">fiber_manual_record</mat-icon>
                                            </span>
                                            <span class="type">
                                                {{ url.url_type }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <mat-divider></mat-divider>
                            </div>
                            <div *ngIf="!contact.isGroup && contact.events && contact.events.length > 0">
                                <div class="detail-item" *ngFor="let event of contact.events; let i= index;">
                                    <div class="icon">
                                        <mat-icon *ngIf="i===0" class="mdi-18px" fontSet="mdi" fontIcon="mdi-cake-variant"></mat-icon>
                                    </div>
                                    <div class="detail">
                                        <span>
                                            {{ event.event_date | date: 'M/d/yyyy' }}
                                        </span>
                                        <span>
                                            <mat-icon class="seprator">fiber_manual_record</mat-icon>
                                        </span>
                                        <span class="type">
                                            {{ event.event_type }}
                                        </span>
                                    </div>
                                </div>
                                <mat-divider></mat-divider>
                            </div>
                            <div class="detail-caption-title">
                                {{ 'CONTACT_DETAILS' | translate }}
                            </div>
                            <div class="detail-item" *ngIf="contact.timezone">
                                <div class="icon">
                                    <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-calendar-clock"></mat-icon>
                                </div>
                                <div class="detail">
                                    <div class="lable-detail">
                                        {{ 'TIME_ZONE' | translate }}
                                    </div>
                                    <div class="detail-data">
                                        {{ contact.timezone }}
                                    </div>
                                </div>
                            </div>
                            <mat-divider *ngIf="contact.timezone"></mat-divider>
                            <div class="detail-item" *ngIf="contact.language">
                                <div class="icon">
                                    <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-flag"></mat-icon>
                                </div>
                                <div class="detail">
                                    <div class="lable-detail">
                                        {{ 'LANGUAGE_LABEL' | translate }}
                                    </div>
                                    <div class="detail-data">
                                        {{ contact.language }}
                                    </div>
                                </div>
                            </div>
                            <mat-divider *ngIf="contact.language"></mat-divider>
                            <div *ngIf="!contact.isGroup && contact.skills && contact.skills.length > 0">
                                <div class="detail-item">
                                    <div class="icon list-chips-icon margin-top-15">
                                        <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-account"></mat-icon>
                                    </div>
                                    <div class="detail list-chips">
                                        <div class="lable-detail">
                                            {{ 'SKILLS' | translate }}
                                        </div>
                                        <div>
                                            <mat-chip-list #contactChipList>
                                                <mat-chip
                                                  *ngFor="let skill of contact.skills"
                                                  [selectable]="true"
                                                  [removable]="false"
                                                  [ngStyle]="{'background': '#607D8B'}"
                                                  >
                                                  <span class="name">{{skill.name}}</span>
                                                </mat-chip>
                                            </mat-chip-list>
                                        </div>
                                    </div>
                                </div>
                                <mat-divider></mat-divider>
                            </div>
                            <div *ngIf="!contact.isGroup && contact.interests && contact.interests.length > 0">
                                <div class="detail-item">
                                    <div class="icon list-chips-icon margin-top-15">
                                        <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-guitar-acoustic"></mat-icon>
                                    </div>
                                    <div class="detail list-chips">
                                        <div class="lable-detail">
                                            {{ 'INTERESTS' | translate }}
                                        </div>
                                        <div>
                                            <mat-chip-list #contactChipList>
                                                <mat-chip
                                                  *ngFor="let interest of contact.interests"
                                                  [selectable]="true"
                                                  [removable]="false"
                                                  [ngStyle]="{'background': '#607D8B'}"
                                                  >
                                                  <span class="name">{{interest.name}}</span>
                                                </mat-chip>
                                            </mat-chip-list>
                                        </div>
                                        <mat-divider></mat-divider>
                                    </div>
                                </div>
                                <mat-divider></mat-divider>
                            </div>
                            <div *ngIf="!contact.isGroup && contact.tags && contact.tags.length > 0">
                                <div class="detail-item">
                                    <div class="icon list-chips-icon margin-top-15">
                                        <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-tag"></mat-icon>
                                    </div>
                                    <div class="detail list-chips">
                                        <div class="lable-detail">
                                            {{ 'TAGS' | translate }}
                                        </div>
                                        <div>
                                            <span>
                                                <mat-chip-list #contactChipTag>
                                                    <mat-chip
                                                      *ngFor="let tag of contact.tags"
                                                      [selectable]="true"
                                                      [removable]="false"
                                                      class="tag-list-chip"
                                                      >
                                                      <span class="name">{{tag.name}}</span>
                                                    </mat-chip>
                                                </mat-chip-list>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <mat-divider></mat-divider>
                            </div>
                            <div *ngIf="!contact.isGroup && contact.contact_list && contact.contact_list.length > 0">
                                <div class="detail-item">
                                    <div class="icon list-chips-icon margin-top-15">
                                        <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-account-box-multiple"></mat-icon>
                                    </div>
                                    <div class="detail list-chips">
                                        <div class="lable-detail">
                                            {{ 'LISTS_LBL' | translate }}
                                        </div>
                                        <div>
                                            <span>
                                                <mat-chip-list #contactChipList>
                                                    <mat-chip
                                                      *ngFor="let list of contact.contact_list"
                                                      [selectable]="true"
                                                      [removable]="false"
                                                      [ngStyle]="{'background': list.color_hex !== null ? list.color_hex : '#888888'}"
                                                      >
                                                      <span class="name">{{list.name}}</span>
                                                    </mat-chip>
                                                </mat-chip-list>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <mat-divider></mat-divider>
                            </div>
                            <div *ngIf="!contact.isGroup && contact.notes">
                                <div class="detail-item">
                                    <div class="icon">
                                        <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-note-text"></mat-icon>
                                    </div>
                                    <div class="detail">
                                        <span>
                                            {{ contact.notes }}
                                        </span>
                                    </div>
                                </div>
                                <mat-divider></mat-divider>
                            </div>
                            <div class="detail-caption-title" *ngIf="!contact.isGroup && contact.custom_fields && contact.custom_fields.length > 0">
                                {{ 'CUSTOM_DETAILS' | translate }}
                            </div>
                            <div *ngIf="!contact.isGroup && contact.custom_fields && contact.custom_fields.length > 0">
                                <div *ngFor="let custom_field of contact.custom_fields">
                                    <div class="detail-item">
                                        <div class="icon">
                                            <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-file-document"></mat-icon>
                                        </div>
                                        <div class="detail">
                                            <div class="lable-detail">
                                                {{ custom_field.field_name }}
                                            </div>
                                            <div class="detail-data">
                                                {{ custom_field.field_value }}
                                            </div>
                                        </div>
                                    </div>
                                    <mat-divider></mat-divider>
                                </div>
                            </div>
                        </div>
                    </vnc-tab>
                    <vnc-tab [id]="'hrData'" tabTitle="{{ 'HR_DATA' | translate }}" >
                        <!-- Add Content for HR Data -->
                        <div class="detail-contact-tab">
                            <div class="detail-item" *ngIf="!contact.isGroup && contact.birthday">
                                <div class="icon">
                                    <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-cake"></mat-icon>
                                </div>
                                <div class="detail">
                                    <div class="lable-detail">
                                        {{ 'DATE_OF_BIRTH' | translate }}
                                    </div>
                                    <div class="detail-data">
                                        {{ contact.birthday | date: "d. MMMM y"}}
                                    </div>
                                </div>
                            </div>
                            <mat-divider *ngIf="!contact.isGroup && contact.birthday"></mat-divider>
                            <div class="detail-item" *ngIf="!contact.isGroup && (contact.gender || contact.marital_status)">
                                <div class="icon">
                                    <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-human-male-female"></mat-icon>
                                </div>
                                <div class="detail">
                                    <div class="multi-field">
                                        <div>
                                            <div class="lable-detail">
                                                {{ 'GENDER' | translate }}
                                            </div>
                                            <div class="detail-data">
                                                {{ contact.gender}}
                                            </div>
                                        </div>
                                        <div class="margin-top-15">
                                            <div class="lable-detail">
                                                {{ 'MARITAL_STATUS' | translate }}
                                            </div>
                                            <div class="detail-data">
                                                {{ contact.marital_status}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <mat-divider *ngIf="!contact.isGroup && (contact.gender || contact.marital_status)"></mat-divider>
                            <div class="detail-item" *ngIf="!contact.isGroup && contact.private_email">
                                <div class="icon">
                                    <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-email"></mat-icon>
                                </div>
                                <div class="detail">
                                    <div class="lable-detail">
                                        {{ 'PRIVATE_EMAIL' | translate }}
                                    </div>
                                    <div class="detail-data">
                                        {{ contact.private_email}}
                                    </div>
                                </div>
                            </div>
                            <mat-divider *ngIf="!contact.isGroup && contact.private_email"></mat-divider>
                            <div class="detail-item" *ngIf="!contact.isGroup && contact.vnc_employee">
                                <div class="icon">
                                    <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-account"></mat-icon>
                                </div>
                                <div class="detail">
                                    <div class="lable-detail">
                                        {{ 'ENGAGEMENT_TYPE' | translate }}
                                    </div>
                                    <div class="detail-data">
                                        <span *ngIf="contact.vnc_employee === 'true'">
                                            {{ 'VNC_EMPLOYEE' | translate }}
                                        </span>
                                        <span *ngIf="contact.vnc_employee === 'false'">
                                            {{ 'NOT_VNC_EMPLOYEE' | translate }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <mat-divider *ngIf="!contact.isGroup && contact.vnc_employee"></mat-divider>
                            <div class="detail-item" *ngIf="!contact.isGroup && (contact.start_date || contact.end_date)">
                                <div class="icon">
                                    <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-calendar-today"></mat-icon>
                                </div>
                                <div class="detail">
                                    <div class="multi-field">
                                        <div *ngIf="contact.start_date">
                                            <div class="lable-detail">
                                                {{ 'START_DATE' | translate }}
                                            </div>
                                            <div class="detail-data">
                                                {{ contact.start_date | date: "d. MMMM y"}}
                                            </div>
                                        </div>
                                        <div class="margin-top-15" *ngIf="contact.end_date">
                                            <div class="lable-detail">
                                                {{ 'END_DATE' | translate }}
                                            </div>
                                            <div class="detail-data">
                                                {{ contact.end_date | date: "d. MMMM y"}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <mat-divider *ngIf="!contact.isGroup && (contact.start_date || contact.end_date)"></mat-divider>
                            <div class="detail-item" *ngIf="!contact.isGroup && (contact.per_week_availability || contact.hourly_rate || contact.payment_mode)">
                                <div class="icon">
                                    <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-currency-usd-circle-outline"></mat-icon>
                                </div>
                                <div class="detail">
                                    <div class="multi-field">
                                        <div *ngIf="contact.per_week_availability">
                                            <div class="lable-detail">
                                                {{ 'PER_WEEK_AVAILABLILITY' | translate }}
                                            </div>
                                            <div class="detail-data">
                                                {{ contact.per_week_availability}}
                                            </div>
                                        </div>
                                        <div *ngIf="contact.hourly_rate" class="margin-top-15">
                                            <div class="lable-detail">
                                                {{ 'HOURLY_RATE' | translate }}
                                            </div>
                                            <div class="detail-data">
                                                {{ contact.hourly_rate}}
                                            </div>
                                        </div>
                                        <div *ngIf="contact.payment_mode" class="margin-top-15">
                                            <div class="lable-detail">
                                                {{ 'PAYMENT_MODE' | translate }}
                                            </div>
                                            <div class="detail-data">
                                                {{ contact.payment_mode}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <mat-divider *ngIf="!contact.isGroup && (contact.per_week_availability || contact.hourly_rate || contact.payment_mode)"></mat-divider>
                            <div *ngIf="!contact.isGroup && contact.im_accounts && contact.im_accounts.length > 0">
                                <div class="detail-item" *ngFor="let im of contact.im_accounts; let i= index;">
                                    <div class="icon">
                                        <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-forum" *ngIf="i === 0"></mat-icon>
                                    </div>
                                    <div class="detail">
                                        <span>
                                            {{ im.im_id }}
                                        </span>
                                        <span>
                                            <mat-icon class="seprator">fiber_manual_record</mat-icon>
                                        </span>
                                        <span class="type">
                                            {{ im.im_type }}
                                        </span>
                                    </div>
                                </div>
                                <mat-divider></mat-divider>
                            </div>
                            <div class="detail-item" *ngIf="!contact.isGroup && (contact.passport_number || contact.passport_expiry)">
                                <div class="icon">
                                    <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-passport"></mat-icon>
                                </div>
                                <div class="detail">
                                    <div class="multi-field">
                                        <div *ngIf="contact.passport_number">
                                            <div class="lable-detail">
                                                {{ 'PASSPORT_NUMBER' | translate }}
                                            </div>
                                            <div class="detail-data">
                                                {{ contact.passport_number}}
                                            </div>
                                        </div>
                                        <div *ngIf="contact.passport_expiry">
                                            <div class="lable-detail">
                                                {{ 'PASSPORT_EXPIRY' | translate }}
                                            </div>
                                            <div class="detail-data">
                                                {{ contact.passport_expiry | date: "d. MMMM y"}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <mat-divider *ngIf="!contact.isGroup && (contact.passport_number || contact.passport_expiry)"></mat-divider>
                            <div class="detail-item" *ngIf="!contact.isGroup && (contact.national_id_number || contact.national_id_expiry)">
                                <div class="icon">
                                    <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-account-card-details"></mat-icon>
                                </div>
                                <div class="detail">
                                    <div class="multi-field">
                                        <div *ngIf="contact.national_id_number">
                                            <div class="lable-detail">
                                                {{ 'NATIONAL_ID_NUMBER' | translate }}
                                            </div>
                                            <div class="detail-data">
                                                {{ contact.national_id_number}}
                                            </div>
                                        </div>
                                        <div *ngIf="contact.national_id_expiry !== null">
                                            <div class="lable-detail">
                                                {{ 'NATIONAL_ID_EXPIRY' | translate }}
                                            </div>
                                            <div class="detail-data">
                                                {{ contact.national_id_expiry | date: "d. MMMM y"}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <mat-divider *ngIf="!contact.isGroup && (contact.national_id_number || contact.national_id_expiry)"></mat-divider>
                        </div>
                    </vnc-tab>
                    <vnc-tab [id]="'accountDetails'" tabTitle="{{ 'ACCOUNTS_DETAILS' | translate }}" >
                        <!-- Add Content for HR Data -->
                        <div class="detail-contact-tab">
                            <div class="detail-item" *ngIf="!contact.isGroup && contact.username">
                                <div class="icon">
                                    <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-account-circle"></mat-icon>
                                </div>
                                <div class="detail">
                                    <div class="lable-detail">
                                        {{ 'USER_NAME' | translate }}
                                    </div>
                                    <div class="detail-data">
                                        {{ contact.username}}
                                    </div>
                                </div>
                            </div>
                            <mat-divider *ngIf="!contact.isGroup && contact.username"></mat-divider>
                            <div class="detail-item" *ngIf="!contact.isGroup && contact.admin">
                                <div class="icon">
                                    <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-account-plus"></mat-icon>
                                </div>
                                <div class="detail">
                                    <div class="lable-detail">
                                        {{ 'ROLE_LBL' | translate }}
                                    </div>
                                    <div class="detail-data">
                                        <span *ngIf="contact.admin === 'true'">{{ 'ADMIN' | translate }}</span>
                                        <span *ngIf="contact.admin === 'false'">{{ 'USER' | translate }}</span>
                                    </div>
                                </div>
                            </div>
                            <mat-divider *ngIf="!contact.isGroup && contact.admin"></mat-divider>
                            <div class="detail-item" *ngIf="!contact.isGroup && contact.agb_accepted">
                                <div class="icon">
                                    <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-checkbox-marked-outline"></mat-icon>
                                </div>
                                <div class="detail">
                                    <div class="lable-detail">
                                        {{ 'ACCEPTED_TERMS' | translate }}
                                    </div>
                                    <div class="detail-data">
                                        <span *ngIf="contact.agb_accepted === 'true'">
                                            {{ 'AGB_FLAG' | translate }}
                                        </span>
                                        <span *ngIf="contact.agb_accepted === 'false'">
                                            {{ 'NOT_AGB_FLAG' | translate }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <mat-divider *ngIf="!contact.isGroup && contact.agb_accepted"></mat-divider>
                            <div class="detail-item" *ngIf="!contact.isGroup && contact.rfc_limit">
                                <div class="icon">
                                    <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-arrow-collapse"></mat-icon>
                                </div>
                                <div class="detail">
                                    <div class="lable-detail">
                                        {{ 'RFC_LIMIT' | translate }}
                                    </div>
                                    <div class="detail-data">
                                        {{ contact.rfc_limit}}
                                    </div>
                                </div>
                            </div>
                            <mat-divider *ngIf="!contact.isGroup && contact.rfc_limit"></mat-divider>
                            <div class="detail-item" *ngIf="!contact.isGroup && contact.video_bridge">
                                <div class="icon">
                                    <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-video"></mat-icon>
                                </div>
                                <div class="detail">
                                    <div class="lable-detail">
                                        {{ 'VIDEO_BRIDGE' | translate }}
                                    </div>
                                    <div class="detail-data">
                                        <span *ngIf="contact.video_bridge === 'true'">
                                            {{ 'ALLOW' | translate }}
                                        </span>
                                        <span *ngIf="contact.video_bridge === 'false'">
                                            {{ 'NOT_ALLOW' | translate }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <mat-divider *ngIf="!contact.isGroup && contact.video_bridge"></mat-divider>
                            <div class="detail-item" *ngIf="!contact.isGroup && contact.omemo">
                                <div class="icon">
                                    <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-lock"></mat-icon>
                                </div>
                                <div class="detail">
                                    <div class="lable-detail">
                                        {{ 'SECURITY_ENCRYPTION' | translate }}
                                    </div>
                                    <div class="detail-data">
                                        <span *ngIf="contact.omemo === 'true'">
                                            {{ 'OMEMO' | translate }}
                                        </span>
                                        <span *ngIf="contact.omemo === 'false'">
                                            {{ 'NOT_OMEMO' | translate }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <mat-divider *ngIf="!contact.isGroup && contact.omemo"></mat-divider>
                            <div class="detail-item projects-item" *ngIf="!contact.isGroup && contact.products">
                                <div class="icon">
                                    <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-briefcase"></mat-icon>
                                </div>
                                <div class="detail">
                                    <div class="lable-detail">
                                        {{ 'PRODUCTS' | translate }}
                                    </div>
                                    <div class="detail-data">
                                        <span *ngFor="let product of contact.products">
                                            {{ product.name }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <mat-divider *ngIf="!contact.isGroup && contact.products"></mat-divider>
                        </div>
                    </vnc-tab>
                </vnc-tabs>
            </div>
            <mat-menu #contactOperationMenu="matMenu">
                <button mat-menu-item (click)="editLoggedInUserDetail()">
                    <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-pencil"></mat-icon>
                  <span>{{ 'EDIT' | translate }}</span>
                </button>
            </mat-menu>
        </div>
    </div>
</div>