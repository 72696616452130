
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { OnDestroy, OnInit, NgZone, Component, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, Inject } from "@angular/core";
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { Subject } from "rxjs";
import { takeUntil, take } from "rxjs/operators";
import { NgForm } from "@angular/forms";
import { ContactUtils } from "src/app/common/utils/contacts-utils";
import { ContactRepository } from "../../repository/contact.repository";
import * as moment from "moment";
import { ToastService } from "src/app/common/service/tost.service";
import { Store } from "@ngrx/store";
import { RootState, getOnlineStatus, getLoggedInUserContact } from "src/app/reducers";
import { ContactImageCropperDialogComponent } from "src/app/shared/components/contact-image-cropper-dialog/contact-image-cropper-dialog.component";
import { environment } from "src/environments/environment";
import * as _ from "lodash";
import { ConfirmationDialogComponent } from "src/app/shared/components/confirmation-dialog/confirmation-dialog.component";
import { ConfirmDialogType } from "src/app/common/models/dialog.model";
import { ContactRootState, getContactFolders, getContactTags } from "../../store";
import { ContactFolder } from "../../models/create-folder.model";
import { CreateFolderComponent } from "../create-contact-folder/create-folder.component";
import { ContactTag } from "../../models/contact-tag.model";
import { CreateTagComponent } from "../create-contact-tag-dialog/create-contact-tag.component";
import { Router } from "@angular/router";
import { CommonUtil } from "src/app/common/utils/common.utils";
import COUNTRY_CODE from "src/app/common/utils/country-code";
import { ContactService } from "src/app/common/service/contact-service";

@Component({
    selector: "vp-create-contactlus-dialog",
    templateUrl: "./create-contact-dialog.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateContactDialogComponent implements OnInit, OnDestroy {

    private isAlive$ = new Subject<boolean>();
    isCompanyContact: boolean = false;
    contactDetails = {
        first_name: "",
        last_name: "",
        middle_name: "",
        job_title: "",
        is_company: "0",
        emails_attributes: [],
        phones_attributes: [],
        addresses_attributes: [],
        urls_attributes: [],
        im_accounts_attributes: [],
        company: "",
        firstLastCharacters: "",
        notes: "",
        custom_fields_attributes: [],
        events_attributes: [],
        contact_list_ids: [],
        tag_list: "",
        bgAvatarColor: "",
        favorite: false
    };
    isEdit: boolean = false;
    phoneTypes: any[] = [];
    addressTypes: any[] = [];
    @ViewChild("contactForm", { static: false }) contactForm: NgForm;
    isFormFieldsValueChange: boolean = false;
    userAvtar: string = null;
    isOnline: boolean = true;
    avatarBase64: string = "";
    isGetBase64Avatar: boolean = false;
    deletedEmails: any [] = [];
    deletedAddress: any[] = [];
    deletedPhone: any[] = [];
    deletedIm: any[] = [];
    deletedUrl: any[] = [];
    deleteEvents: any[] = [];
    deleteCustomField: any[] = [];
    showMoreFields: boolean = false;
    label: any;
    isShowMoreField: boolean = true;
    contactLists: ContactFolder[] = [];
    searchListText: string = "";
    selectedLists: ContactFolder[] = [];
    contactTags: ContactTag[] = [];
    searchTagText: string = "";
    selectedTags: ContactTag[] = [];
    isGlobalContact: boolean = false;
    isMobileView = CommonUtil.isOnMobileDevice();
    isSubmit: boolean = false;
    countryCodes: any [] = [];
    searchCountry: string = "";
    isOnMobile = CommonUtil.isOnMobileDevice();
    isAdmin: boolean;
    offset: number = 0;
    limit: number = 25;
    duplicateEmails: boolean[] = [];
    duplicatePhone: boolean[] = [];
    avatarEmail: string = "";

    constructor(
        public dialogRef: MatDialogRef<CreateContactDialogComponent>,
        private broadcaster: Broadcaster,
        private ngZone: NgZone,
        private contactService: ContactService,
        private changeDetectorRef: ChangeDetectorRef,
        private contactRepository: ContactRepository,
        private toastService: ToastService,
        private store: Store<RootState | ContactRootState>,
        private matDialog: MatDialog,
        private router: Router,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.countryCodes = COUNTRY_CODE;
        this.countryCodes.map(c => {
            c.flag = this.toFlag(c.code);
        });
        this.broadcaster.on<any>(BroadcastKeys.HIDE_CREATE_CONTACT_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            this.ngZone.run(() => {
                this.close();
            });
        });
        this.broadcaster.on<any>(BroadcastKeys.SUCCESS_CREATE_UDATE_CONTACT_REQUEST).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            this.ngZone.run(() => {
                this.isFormFieldsValueChange = false;
                this.changeDetectorRef.markForCheck();
                this.close();
            });
        });
        this.broadcaster.on<any>(BroadcastKeys.CREATE_UPDATE_REQUEST_FAIL).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            this.ngZone.run(() => {
                console.log("[CREATE_UPDATE_REQUEST_FAIL]");
                this.isSubmit = false;
                this.changeDetectorRef.markForCheck();
            });
        });
        this.store.select(getOnlineStatus).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            this.isOnline = res;
            this.changeDetectorRef.markForCheck();
        });
        this.store.select(getLoggedInUserContact).pipe(takeUntil(this.isAlive$)).subscribe(contact => {
            this.isAdmin = contact && contact.admin === "true";
        });
        this.store.select(getContactFolders).pipe(takeUntil(this.isAlive$)).subscribe((contactFolders: ContactFolder[]) => {
            this.contactLists = contactFolders;
            this.changeDetectorRef.markForCheck();
        });
        this.store.select(getContactTags).pipe(takeUntil(this.isAlive$)).subscribe((contactTags: ContactTag[]) => {
            this.contactTags = contactTags;
            this.changeDetectorRef.markForCheck();
        });
        if (this.data && this.data.isEdit) {
            this.isEdit = true;
            this.changeDetectorRef.markForCheck();
        }
    }

    ngOnInit(): void {
        if (this.isEdit) {
            this.contactDetails = ContactUtils.initializeEditContactDetailState(this.data.contact, this.contactDetails);
            let avtmail = (!!this.data.contact.jid) ? this.data.contact.jid : "";
            let avtemail = (!!this.data.contact.emails && (this.data.contact.emails.length > 0)) ? this.data.contact.emails[0].email : "";
            this.avatarEmail = (!!avtmail) ? avtmail : avtemail;
            console.log("editContactAvatar: ", avtemail, avtmail, this.data);
            this.avatarBase64 = this.contactRepository.getCachedAvatar(this.avatarEmail);
            this.isCompanyContact = this.data.contact.is_company;

            if (this.data.contact.contact_list && this.data.contact.contact_list.length > 0) {
                const contactList = this.data.contact.contact_list;
                contactList.map( cl => {
                    const item = this.contactLists.filter(c => c.id === cl.id)[0];
                    if (!!item) {
                        this.selectedLists.push(item);
                    }
                });
            }
            if (this.data.contact.tags && this.data.contact.tags.length > 0) {
                this.selectedTags = this.data.contact.tags;
            }
            if (this.data.contact.is_global) {
                this.isGlobalContact = true;
            }
            if (this.data.isLoggedInUser) {
                this.isGlobalContact = false;
            }
            this.changeDetectorRef.markForCheck();
        } else {
            this.contactDetails = ContactUtils.getInitialContactDetailState();
            const url = this.router.url;
            if (url.startsWith("/contactplus/list/")) {
                let id = url.substr(url.lastIndexOf("/") + 1 );
                if (this.data && this.data.listId) {
                    id = this.data.listId;
                }
                this.selectedLists = this.contactLists.filter(cl => cl.id.toString() === id);
            } else if (url.startsWith("/contactplus/tag/")) {
                const id = url.substr(url.lastIndexOf("/") + 1 );
                this.selectedTags = this.contactTags.filter(cl => cl.id.toString() === id);
            }
            if (this.data && this.data.listId && this.selectedLists.length == 0) {
                let id = this.data.listId;
                this.contactLists.map(cl => {
                    if (cl.id == id) {
                        this.selectedLists.push(cl);
                    }
                });
            }

        }
        setTimeout(() => {
            this.contactForm.valueChanges.pipe(takeUntil(this.isAlive$)).subscribe(res => {
                if (!!res && res.firstName !== "") {
                    this.isFormFieldsValueChange = true;
                }
                this.changeDetectorRef.markForCheck();
            });
        }, 100);
        this.broadcaster.on<any>("contactAvtarUpload").pipe(takeUntil(this.isAlive$)).subscribe(res => {
            if (!!res) {
                this.avatarBase64 = res.operation;
                this.isGetBase64Avatar = true;
                this.isFormFieldsValueChange = true;
                this.changeDetectorRef.markForCheck();
            }
        });
        if (!!this.data && this.data.tag) {
            this.selectedTags = [this.data.tag];
        }
        if (!!this.data && this.data.listItem) {
            this.selectedLists = [this.data.listItem];
        }
        setTimeout(() => {
            this.checkEmailValidOnLoad();
        }, 500);

        this.changeDetectorRef.markForCheck();
    }

    onEmailChange(event,index) {
        const enteredText = (event.target as HTMLInputElement).value;
        const emailPattern = /^[!#$%&'*+\-/=?^_`{|}~.0-9A-Za-z]+(\.[!#$%&'*+\-/=?^_`{|}~.0-9A-Za-z]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,})$/;
        if (emailPattern.test(enteredText)) {
            this.contactService.seachContactDuplicate(this.offset, this.limit, enteredText).pipe(take(1)).subscribe(res => {
                if (res.total_count > 0) {
                    const isEmailExist = res.contacts.filter(c => c.emails.filter(p => {
                        if (p.email === enteredText) {
                            return c;
                        }
                    }).length > 0);
                    if (isEmailExist.length > 0) {
                        this.duplicateEmails[index] = true;
                        this.changeDetectorRef.detectChanges();
                    }
                    else {
                        this.duplicateEmails[index] = false;
                        this.changeDetectorRef.detectChanges();
                    }
                } else {
                    this.duplicateEmails[index] = false;
                    this.changeDetectorRef.detectChanges();
                }
            });
        } else {
            this.duplicateEmails[index] = false;
            this.changeDetectorRef.detectChanges();
        }
    }

    onContactNumberChange(event,index){
        const enteredText = (event.target as HTMLInputElement).value;
        if (!isNaN(Number(enteredText)) && enteredText.length > 2) {
            this.contactService.seachContactDuplicate(this.offset, this.limit, enteredText).pipe(take(1)).subscribe(res => {
                if (res.total_count > 0) {
                    const isPhoneExist = res.contacts.filter(c => c.phones.filter(p => {
                        const phone = p.phone.replace(/\+/g, "");
                        if (phone === enteredText) {
                            return c;
                        }
                    }).length > 0);
                    if (isPhoneExist.length > 0) {
                        this.duplicatePhone[index] = true;
                        this.changeDetectorRef.detectChanges();
                    }
                    else {
                        this.duplicatePhone[index] = false;
                        this.changeDetectorRef.detectChanges();
                    }
                } else {
                    this.duplicatePhone[index] = false;
                    this.changeDetectorRef.detectChanges();
                }
            });
        } else {
            this.duplicatePhone[index] = false;
            this.changeDetectorRef.detectChanges();
        }
    }

    close(): void {
        if (this.isFormFieldsValueChange) {
            this.openConfirmationDialog();
            return;
        }
        this.dialogRef.close();
    }

    addEmail(index) {
        this.contactDetails.emails_attributes.splice(index + 1, 0, {
            email: ""
        });
        setTimeout(() => {
            this.checkEmailValidOnLoad();
        }, 500);
        this.changeDetectorRef.markForCheck();
    }

    removeEmail(index) {
        if (this.contactDetails.emails_attributes[index].email !== "") {
            if (this.contactDetails.emails_attributes[index].id) {
                this.deletedEmails.push(this.contactDetails.emails_attributes[index]);
            }
            this.changeDetectorRef.markForCheck();
        }

        if (this.contactDetails.emails_attributes.length > 1) {
            this.contactDetails.emails_attributes.splice(index, 1);
            this.changeDetectorRef.markForCheck();
        } else if (this.contactDetails.emails_attributes.length === 1) {
            this.contactDetails.emails_attributes[0].email = "";
            this.changeDetectorRef.markForCheck();
        }
    }

    addAddress(index) {
        this.contactDetails.addresses_attributes.splice(index + 1, 0, {
            address_type: "home",
            city: "",
            street1: "",
            state: "",
            postcode: "",
            country_code: ""
        });
        this.changeDetectorRef.markForCheck();
    }

    removeAddress(index) {
        if (this.contactDetails.addresses_attributes[index].street1 !== "" || this.contactDetails.addresses_attributes[index].state !== "" ||
            this.contactDetails.addresses_attributes[index].city !== "" || this.contactDetails.addresses_attributes[index].country_code !== "" ||
            this.contactDetails.addresses_attributes[index].postcode !== "") {
            this.contactDetails.addresses_attributes[index].street1 = "";
            this.contactDetails.addresses_attributes[index].state = "";
            this.contactDetails.addresses_attributes[index].city = "";
            this.contactDetails.addresses_attributes[index].postcode = "";
            this.contactDetails.addresses_attributes[index].country_code = "";
            if (this.contactDetails.addresses_attributes[index].id) {
                this.deletedAddress.push(this.contactDetails.addresses_attributes[index]);
            }
            this.changeDetectorRef.markForCheck();
        }

        if (this.contactDetails.addresses_attributes.length > 1) {
            this.contactDetails.addresses_attributes.splice(index, 1);
            this.changeDetectorRef.markForCheck();
        }
    }

    addPhone(index) {
        this.contactDetails.phones_attributes.splice(index + 1, 0, {
            phone_type: "home",
            phone: ""
        });
        this.changeDetectorRef.markForCheck();
    }

    removePhone(index) {
        if (this.contactDetails.phones_attributes[index].phone !== "") {
            this.contactDetails.phones_attributes[index].phone = "";
            if (this.contactDetails.phones_attributes[index].id) {
                this.deletedPhone.push(this.contactDetails.phones_attributes[index]);
            }
            this.changeDetectorRef.markForCheck();
        }

        if (this.contactDetails.phones_attributes.length > 1) {
            this.contactDetails.phones_attributes.splice(index, 1);
            this.changeDetectorRef.markForCheck();
        }
    }

    addIm(index) {
        this.contactDetails.im_accounts_attributes.splice(index + 1, 0, {
            im_type: "XMPP",
            im_id: ""
        });
        this.changeDetectorRef.markForCheck();
    }

    removeIm(index) {
        if (this.contactDetails.im_accounts_attributes[index].im_id !== "") {
            this.contactDetails.im_accounts_attributes[index].im_id = "";
            if (this.contactDetails.im_accounts_attributes[index].id) {
                this.deletedIm.push(this.contactDetails.im_accounts_attributes[index]);
            }
            this.changeDetectorRef.markForCheck();
        }

        if (this.contactDetails.im_accounts_attributes.length > 1) {
            this.contactDetails.im_accounts_attributes.splice(index, 1);
            this.changeDetectorRef.markForCheck();
        }
    }

    addUrl(index) {
        this.contactDetails.urls_attributes.splice(index + 1, 0, {
            url_type: "home",
            url: ""
        });
        this.changeDetectorRef.markForCheck();
    }

    removeUrl(index) {
        if (this.contactDetails.urls_attributes[index].url !== "") {
            this.contactDetails.urls_attributes[index].url = "";
            if (this.contactDetails.urls_attributes[index].id) {
                this.deletedUrl.push(this.contactDetails.urls_attributes[index]);
            }
            this.changeDetectorRef.markForCheck();
        }

        if (this.contactDetails.urls_attributes.length > 1) {
            this.contactDetails.urls_attributes.splice(index, 1);
            this.changeDetectorRef.markForCheck();
        }
    }

    submit(): void {
        if (this.contactDetails.first_name === "" || this.contactDetails.first_name === undefined) {
            this.toastService.show("FIRST_NAME_REQUIRED");
            return;
        }
        let isValidEmail: boolean = true;
        this.checkEmailValidOnLoad();
        if (this.contactDetails.emails_attributes.length > 0) {
            this.contactDetails.emails_attributes.map(item => {
                if (item?.email != "") { isValidEmail = ContactUtils.validateMail(item.email); }
            });
        }
        if (!isValidEmail) {
            this.toastService.show("ENTER_VALID_EMAIL_MSG");
            return;
        }
        this.contactDetails.is_company = this.isCompanyContact ? "1" : "0";
        if (this.isGetBase64Avatar) {
            if (this.avatarBase64 === "") {
                this.contactDetails["remove_avatar"] = "1";
            } else {
                this.contactDetails["avatar_base64"] = this.avatarBase64;
            }
        }
        if (this.selectedLists.length > 0) {
            this.contactDetails.contact_list_ids = this.selectedLists.map( l => l.id);
        }
        // this.contactDetails.tag_list = "test,123,567";
        if (this.selectedTags.length > 0) {
            this.contactDetails.tag_list = this.selectedTags.map( t => t.name).toString();
        }
        if (!this.isEdit) {
            this.isSubmit = true;
            this.changeDetectorRef.markForCheck();
            this.contactRepository.createContact(this.contactDetails);
        } else {
            console.log("[updateContact]: contactDetails", this.contactDetails);

            const contactDetailItem = _.clone(this.contactDetails, true);
            if (!!this.deletedEmails && this.deletedEmails.length > 0) {
                this.deletedEmails.map(c => c._destroy = "1");
                contactDetailItem.emails_attributes = [...contactDetailItem.emails_attributes, ...this.deletedEmails];
            }
            if (!!this.deletedAddress && this.deletedAddress.length > 0) {
                this.deletedAddress.map(c => c._destroy = "1");
                contactDetailItem.addresses_attributes = [...contactDetailItem.addresses_attributes, ...this.deletedAddress];
            }
            if (!!this.deletedPhone && this.deletedPhone.length > 0) {
                this.deletedPhone.map(c => c._destroy = "1");
                contactDetailItem.phones_attributes = [...contactDetailItem.phones_attributes, ...this.deletedPhone];
            }
            if (!!this.deletedIm && this.deletedIm.length > 0) {
                this.deletedIm.map(c => c._destroy = "1");
                contactDetailItem.im_accounts_attributes = [...contactDetailItem.im_accounts_attributes, ...this.deletedIm];
            }
            if (!!this.deletedUrl && this.deletedUrl.length > 0) {
                this.deletedUrl.map(c => c._destroy = "1");
                contactDetailItem.urls_attributes = [...contactDetailItem.urls_attributes, ...this.deletedUrl];
            }
            if (!!this.deleteCustomField && this.deleteCustomField.length > 0) {
                this.deleteCustomField.map(c => c._destroy = "1");
                contactDetailItem.custom_fields_attributes = [...contactDetailItem.custom_fields_attributes, ...this.deleteCustomField];
            }
            if (!!this.deleteEvents && this.deleteEvents.length > 0) {
                this.deleteEvents.map(c => c._destroy = "1");
                contactDetailItem.events_attributes = [...contactDetailItem.events_attributes, ...this.deleteEvents];
            }
            console.log("[updateContact]: ", contactDetailItem);
            if (this.data.isLoggedInUser) {
                if (this.isGetBase64Avatar) {
                    if (this.avatarBase64 === "") {
                        this.contactRepository.removeProfileAvatar();
                    } else {
                        this.contactRepository.updateUserAvatar(this.avatarBase64);
                    }
                }
                this.isSubmit = true;
                this.changeDetectorRef.markForCheck();
                this.contactRepository.updateLoggedInUserContact(contactDetailItem);
                this.contactRepository.markContactFavorite(contactDetailItem.favorite,[this.data.contact]);
            } else {
                this.isSubmit = true;
                this.changeDetectorRef.markForCheck();
                this.contactRepository.updateContact(contactDetailItem, contactDetailItem.favorite);
                this.contactRepository.markContactFavorite(contactDetailItem.favorite, [this.data.contact]);
            }
        }
    }

    openCropperDialog(): void {
        if (this.isGlobalContact) {
            return;
        }
        if (!this.isOnline) {
            this.toastService.show("APP_OFFLINE");
            return;
        }
        return;
        this.matDialog.open(ContactImageCropperDialogComponent, {
            maxWidth: "100%",
            autoFocus: false,
            panelClass: "contact-image-cropper-dialog",
            data : { contactUserAvtar : this.userAvtar, avatarURL: this.avatarBase64 },
          });
    }

    ngOnDestroy(): void {
        this.isAlive$.next(false);
        this.isAlive$.complete();
    }

    openConfirmationDialog(): void {
        const dlg = this.matDialog.open(ConfirmationDialogComponent, {
            maxWidth: "100%",
            autoFocus: false,
            panelClass: "confirm_contactplus_dialog",
            data : { dialogType: ConfirmDialogType.SAVE_CONFIRMATION, message_title: "SAVE_CHANGES", message_body: "CONTACT_NOT_SAVE_INFO_MSG"}
        });
        dlg.afterClosed().pipe(take(1)).subscribe(res => {
            if (!!res && res.confirmation) {
                if (res.confirmation === "yes") {
                    this.submit();
                } else {
                    this.dialogRef.close();
                }
            }
        });
    }

    showCustomField() {
        if (this.isGlobalContact) {
            return;
        }
        this.isShowMoreField = false;
        this.label = "";
        this.changeDetectorRef.markForCheck();
    }

    hideCustomField() {
        this.isShowMoreField = true;
        this.label = "";
        this.changeDetectorRef.markForCheck();
    }

    addCustomField(label) {
        this.contactDetails.custom_fields_attributes.push( {
            field_name: label,
            field_value: ""
        });
        this.changeDetectorRef.markForCheck();
        this.hideCustomField();
    }

    removeCustomField(index) {
        if (this.contactDetails.custom_fields_attributes[index].field_name !== "") {
            this.contactDetails.custom_fields_attributes[index].field_name = "";
            if (this.contactDetails.custom_fields_attributes[index].id) {
                this.deleteCustomField.push(this.contactDetails.custom_fields_attributes[index]);
            }
            this.changeDetectorRef.markForCheck();
        }

        if (this.contactDetails.custom_fields_attributes.length > 0) {
            this.contactDetails.custom_fields_attributes.splice(index, 1);
            this.changeDetectorRef.markForCheck();
        }
    }

    addEvent(index) {
        this.contactDetails.events_attributes.splice(index + 1, 0, {
            event_type: "birthday",
            event_date: ""
        });
        this.changeDetectorRef.markForCheck();
    }

    removeEvent(index) {
        if (this.contactDetails.events_attributes[index].event_type !== "") {
            this.contactDetails.events_attributes[index].event_type = "";
            if (this.contactDetails.events_attributes[index].id) {
                this.deleteEvents.push(this.contactDetails.events_attributes[index]);
            }
            this.changeDetectorRef.markForCheck();
        }

        if (this.contactDetails.events_attributes.length > 1) {
            this.contactDetails.events_attributes.splice(index, 1);
            this.changeDetectorRef.markForCheck();
        }
    }

    searchFromList(): void {
        if (this.searchListText !== "") {
            this.contactLists = this.contactLists.filter(list => list.name.toLowerCase().includes(this.searchListText.toLowerCase()));
            this.changeDetectorRef.markForCheck();
        } else {
            this.store.select(getContactFolders).pipe(take(1)).subscribe((contactFolders: ContactFolder[]) => {
                this.contactLists = contactFolders;
                this.changeDetectorRef.markForCheck();
            });
        }
    }

    addToSelectList(list: ContactFolder): void {
        if (!this.isSelectedList(list)) {
            this.selectedLists.push(list);
        }
    }

    remove(list: ContactFolder): void {
        const index = this.selectedLists.indexOf(list);
        if (index >= 0) {
          this.selectedLists.splice(index, 1);
        }
        this.changeDetectorRef.markForCheck();
    }

    addList() {
        if (!this.isOnline) {
            this.toastService.show("APP_OFFLINE");
            return;
        }
        this.matDialog.open(CreateFolderComponent, {
          width: "325px",
          height: "212px",
          autoFocus: true,
          panelClass: "contact_folder-create_dialog",
          disableClose: true,
          data: { targetFolder: null, isRename: false }
        });
    }

    isSelectedList(list: any): boolean {
        let isInSelectedList: boolean = false;
        const item = this.selectedLists.filter(cl => cl.id === list.id)[0];
        if (!!item) {
            isInSelectedList = true;
        }
        return isInSelectedList;
    }

    searchFromTags(): void {
        if (this.searchTagText !== "") {
            this.contactTags = this.contactTags.filter(tag => tag.name.toLowerCase().includes(this.searchTagText.toLowerCase()));
            this.changeDetectorRef.markForCheck();
        } else {
            this.store.select(getContactTags).pipe(take(1)).subscribe((contactTags: ContactTag[]) => {
                this.contactTags = contactTags;
                this.changeDetectorRef.markForCheck();
            });
        }
    }

    isSelectedTag(tag: any): boolean {
        let isInSelectedTag: boolean = false;
        const item = this.selectedTags.filter(cl => cl.id === tag.id)[0];
        if (!!item) {
            isInSelectedTag = true;
        }
        return isInSelectedTag;
    }

    addToSelectTag(tag: ContactTag): void {
        if (!this.isSelectedTag(tag)) {
            this.selectedTags.push(tag);
        }
    }

    addTag(): void {
        const dialog = this.matDialog.open(CreateTagComponent, {
            width: "325px",
            height: "212px",
            autoFocus: true,
            panelClass: "create-tag-dialog"
        });
        dialog.afterClosed().pipe(take(1)).subscribe(res => {
            if (!!res && res.tag && res.tag !== "") {
                this.contactTags.push({
                    id: new Date().getTime(),
                    name: res.tag,
                    contacts_count: 0
                });
                this.changeDetectorRef.markForCheck();
            }
        });
    }

    removeTag(tag: ContactTag): void {
        const index = this.selectedTags.indexOf(tag);
        if (index >= 0) {
          this.selectedTags.splice(index, 1);
        }
        this.changeDetectorRef.markForCheck();
    }

    toFlag(code: string): string {
        if (code === null || code === undefined) {
            return "";
        }
        const base = 127462 - 65;
        const cc = code.toUpperCase();
        const res = String.fromCodePoint(...cc.split("").map(c => base + c.charCodeAt(0)));
        return res;
    }

    countryItemSelect(data: any, countryCode: string): void {
        if (data.type === "phone") {
            this.contactDetails.phones_attributes[data.index].country_code = countryCode;
        } else if (data.type === "address") {
            this.contactDetails.addresses_attributes[data.index].country_code = countryCode;
        }
        this.searchCountry = "";
        this.changeDetectorRef.markForCheck();
    }

    getCountryNameByCode(code: string): string {
        if (code === null || code === undefined) {
            return "";
        }
        const countryName = this.countryCodes.filter(c => c.code === code)[0];
        if (!!countryName) {
            return countryName.name;
        } else {
            return "";
        }
    }

    getHeight() {
        const modal = document.getElementsByClassName("create_contactplus_dialog");
        if (modal) {
            return modal[0].clientHeight - 114;
        }
    }

    checkEmailValidOnLoad() {
        if ( this.contactDetails && this.contactDetails.emails_attributes && this.contactDetails.emails_attributes.length > 0) {
            this.contactDetails.emails_attributes.map((item, index) => {
                let inputElement = document.getElementById("emailId" + index);
                if (inputElement) {
                    inputElement.focus();
                    inputElement.blur();
                }
            });
        }
    }
}
