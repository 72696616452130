
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, OnDestroy, NgZone, ChangeDetectorRef, ChangeDetectionStrategy, Inject, HostListener } from "@angular/core";
import { Subject } from "rxjs";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { ContactRepository } from "../../repository/contact.repository";
import { ToastService } from "src/app/common/service/tost.service";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { takeUntil, take } from "rxjs/operators";
import { ContactService } from "src/app/common/service/contact-service";
import { Contact } from "src/app/common/models";
import { environment } from "src/environments/environment";
import { CommonUtil } from "src/app/common/utils/common.utils";
import { ContactImageCropperDialogComponent } from "src/app/shared/components/contact-image-cropper-dialog/contact-image-cropper-dialog.component";

@Component({
    selector: "vp-create-contact-group-dialog",
    templateUrl: "./create-contact-group-dialog.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateContactGroupDailogComponent implements OnInit, OnDestroy {

    private isAlive$ = new Subject<boolean>();
    isEdit: boolean = false;
    groupName: string = "";
    isMoreContact: boolean = false;
    offset: number = 0;
    limit: number = 25;
    contacts: Contact[] = [];
    selectedAllContact: any[] = [];
    finalContacts: any[] = [];
    selectedFinalContact: any[] = [];
    selectedFinalContactItem: any[] = [];
    searchText: string = "";
    isLoading: boolean = true;
    type: string = "";
    isOnMobileDevice: boolean = CommonUtil.isOnMobileDevice();
    searchType: any = [
        {
            value: "contact",
            viewValue: "contact"
        },
        {
            value: "global",
            viewValue: "global"
        },
    ];
    searchIn: string = "contact";
    avatarBase64: string = "";
    isGetBase64Avatar: boolean = false;
    isGlobalContact: boolean = false;
    userAvtar: string = null;
    isSearchView: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<CreateContactGroupDailogComponent>,
        private broadcaster: Broadcaster,
        private ngZone: NgZone,
        private changeDetectorRef: ChangeDetectorRef,
        private contactRepository: ContactRepository,
        private toastService: ToastService,
        private contactService: ContactService,
        private matDialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        if (this.data && this.data.isEdit) {
            this.isEdit = true;
            this.groupName = this.data.contact.fullName;
            if (this.data.memberContacts && this.data.memberContacts.length > 0) {
                this.finalContacts = this.data.memberContacts;
            }
            this.avatarBase64 = this.data.contact.avatar;
            if (this.data.contact.is_global) {
                this.isGlobalContact = true;
            }
            this.changeDetectorRef.markForCheck();
        }
    }

    close(): void {
        this.dialogRef.close();
    }

    ngOnInit(): void {
        this.broadcaster.on<any>(BroadcastKeys.HIDE_CONTACT_GROUP_CREATE_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            this.ngZone.run(() => {
                this.close();
            });
        });
        if (this.searchIn === "global") {
            this.getContacts(this.searchText, true);
        }
        else {
            this.getContacts(this.searchText);
        }
        this.broadcaster.on<any>("contactAvtarUpload").pipe(takeUntil(this.isAlive$)).subscribe(res => {
            if (!!res) {
                this.avatarBase64 = res.operation;
                this.isGetBase64Avatar = true;
                this.changeDetectorRef.markForCheck();
            }
        });
    }

    getContacts(searchText?: string, global: boolean = false): void {
        this.isLoading = true;
        this.contactService.getAllDirectoryContact(this.offset, this.limit, searchText, global).pipe(take(1)).subscribe(res => {
            this.contacts = [];
            const contactItem = this.contactRepository.mapContacts(res.contacts);
            this.contacts = this.contacts.concat(contactItem);
            this.contacts = this.contacts.filter(c => !c.deleted_at);
            let newOffset = this.limit + res.offset;
            if (contactItem.length === this.limit) {
                this.isMoreContact = true;
            } else {
                newOffset = 0;
                this.isMoreContact = false;
            }
            this.offset = newOffset;
            this.isLoading = false;
            this.changeDetectorRef.markForCheck();
        }, error => {
            this.isLoading = false;
            this.changeDetectorRef.markForCheck();
            this.toastService.showPlainMessage(error);
        });
    }

    selectContactItem(data: any, index: number, $event: MouseEvent): void {
        $event.preventDefault();
        if (this.selectedAllContact.indexOf(data) === -1) {
            if ($event.ctrlKey) {
                this.selectedAllContact.push(data);
            } else {
                this.selectedAllContact = [];
                this.selectedAllContact.push(data);
            }
        } else {
            if (this.selectedAllContact.length > 1) {
                this.selectedAllContact.splice(this.selectedAllContact.indexOf(data), 1);
            }
        }
        this.addSelectedContact();
        this.changeDetectorRef.markForCheck();
    }

    isSelectedContact(contact: Contact): boolean {
        return this.selectedAllContact.indexOf(contact) !== -1;
    }

    addSelectedContact(): void {
        this.selectedAllContact.forEach(add => {
            const objCopy = Object.assign({}, add);
            if (!this.isAlreadyAdded(objCopy)) {
                this.finalContacts.push(objCopy);
            }
        });
        this.selectedAllContact = [];
        this.changeDetectorRef.markForCheck();
    }

    isAlreadyAdded(add: any) {
        let isAdded = false;
        for (let i = 0; i < this.finalContacts.length; i++) {
            if (this.finalContacts[i].id === add.id) {
                isAdded = true;
                break;
            }
        }
        return isAdded;
    }

    removeSelectedContact(): void {
        this.selectedFinalContactItem.forEach(add => {
            const addIndex = this.finalContacts.findIndex((e) => e.id === add.id);
            if (addIndex !== -1) {
                this.finalContacts.splice(addIndex, 1);
            }
        });
        this.selectedFinalContactItem = [];
        this.changeDetectorRef.markForCheck();
    }

    isSelectFinalContact(contact: any): boolean {
        return this.selectedFinalContactItem.indexOf(contact) !== -1;
    }

    selectFinalContactsItem(data: any, index: number, $event: MouseEvent): void {
        $event.preventDefault();
        if (this.selectedFinalContactItem.indexOf(data) === -1) {
            if ($event.ctrlKey) {
                this.selectedFinalContactItem.push(data);
            } else {
                this.selectedFinalContactItem = [];
                this.selectedFinalContactItem.push(data);
            }
        } else {
            if (this.selectedFinalContactItem.length > 1) {
                this.selectedFinalContactItem.splice(this.selectedFinalContactItem.indexOf(data), 1);
            }
        }
    }

    saveContactGroup(): void {
        if (this.groupName === "" || this.groupName === undefined) {
            this.toastService.show("GROUPNAME_NOT_BLANK_ERROR");
            return;
        }
        if (!!this.finalContacts && this.finalContacts.length === 0) {
            this.toastService.show("GROUP_MEMBER_REQUIRE");
            return;
        }
        const name = this.groupName;
        const ids = this.finalContacts.map(c => c.id);
        if (!this.isEdit) {
            const body = {
                "group": {
                    "contact_group" : { "name" : name , "contact_ids" : ids }
                }
            };
            if (this.isGetBase64Avatar) {
                if (this.avatarBase64 === "") {
                    body.group.contact_group["remove_avatar"] = "1";
                } else {
                    body.group.contact_group["avatar_base64"] = this.avatarBase64;
                }
            }
            this.contactRepository.createContactGroup(body);
        } else {
            const id = this.data.contact.id;
            const body = {
                "group": {
                    "contact_group" : { "name" : name , "contact_ids" : ids }
                },
                "groupId": id
            };
            if (this.isGetBase64Avatar) {
                if (this.avatarBase64 === "") {
                    body.group.contact_group["remove_avatar"] = "1";
                } else {
                    body.group.contact_group["avatar_base64"] = this.avatarBase64;
                }
            }
            this.contactRepository.updateContactGroup(body);
        }
    }

    ngOnDestroy(): void {
        this.isAlive$.next(false);
        this.isAlive$.complete();
    }

    searchTextWord(): void {
        this.offset = 0;
        this.contacts = [];
        if (this.searchIn === "global") {
            this.getContacts(this.searchText, true);
        }
        else {
            this.getContacts(this.searchText);
        }
    }

    @HostListener("window:keydown", ["$event"])
    keyEvent(event: KeyboardEvent): void {
        const isOnMobileDevice = CommonUtil.isOnMobileDevice();
        if (event.code === "Enter" && !environment.isCordova && !isOnMobileDevice) {
            this.saveContactGroup();
        }
    }

    removeContact(contact: Contact): void {
        const addIndex = this.finalContacts.findIndex((e) => e.id === contact.id);
        if (addIndex !== -1) {
            this.finalContacts.splice(addIndex, 1);
        }
        this.selectedFinalContactItem = [];
        this.changeDetectorRef.markForCheck();
    }

    scrollMoreContacts(event: any): void {
        if (event && event.target && !this.isLoading) {
            if (Math.ceil(event.target.scrollTop) + event.target.offsetHeight >= event.target.scrollHeight) {
                if (this.isMoreContact) {
                    if (this.searchIn === "global") {
                        this.getContacts(this.searchText, true);
                    }
                    else {
                        this.getContacts(this.searchText);
                    }
                }
            }
        }
    }

    openCropperDialog(): void {
        if (this.isGlobalContact) {
            return;
        }
        this.matDialog.open(ContactImageCropperDialogComponent, {
            maxWidth: "100%",
            autoFocus: false,
            panelClass: "contact-image-cropper-dialog",
            data : { contactUserAvtar : this.userAvtar, avatarURL: this.avatarBase64 },
          });
    }

    openSearchView(value: boolean): void {
        this.isSearchView = value;
        if (!value) {
            this.getContacts("");
            this.searchText = "";
        }
        this.changeDetectorRef.markForCheck();
    }

    emptySearchText(): void {
        this.searchText = "";
        this.changeDetectorRef.markForCheck();
    }

    searchFromText(ev: any): void {
        if (ev.key === "Enter") {
            this.searchTextWord();
        }
    }

    onChangeType() {
        if (this.searchIn === "global") {
            this.offset = 0;
            this.getContacts(this.searchText, true);
        }
        else {
            this.offset = 0;
            this.getContacts(this.searchText);
        }
    }
}